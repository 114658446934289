/**
 * THIS CLASS IS FOR STATIC OPTIONS WHICH IS USED IN ALL COMPONENTS
 */
export default () => {
    /**
     * FOR USE OF CAPTURE STYLES IN MANAGE SITE MODULE
     * CALLING FROM: 
     * 1) appfrontend\src\views\capture-setting\style-components\BarSetting.vue
     * 2) appfrontend\src\views\capture-setting\style-components\SlideBarSetting.vue
     */
    const barPlacementOptions = [
        { id: 'top', text: 'Top' },
        { id: 'bottom', text: 'Bottom' },
    ];

    /**
     * FOR USE OF CAPTURE STYLES IN MANAGE SITE MODULE
     * CALLING FROM: 
     * 1) appfrontend\src\views\capture-setting\style-components\BarSetting.vue
     * 2) appfrontend\src\views\capture-setting\style-components\SlideBarSetting.vue
     */
    const barSizeOptions = [
        { id: 'large', text: 'Large - 50px height, 17px font' },
        { id: 'regular', text: 'Regular - 30px height, 14px font' },
    ];

    /**
     * FOR USE OF CAPTURE STYLES IN MANAGE SITE MODULE
     * CALLING FROM: 
     * 1) appfrontend\src\views\capture-setting\style-components\BarSetting.vue
     * 2) appfrontend\src\views\capture-setting\style-components\ModalSetting.vue
     * 3) appfrontend\src\views\capture-setting\style-components\PageTakeOverSetting.vue
     * 4) appfrontend\src\views\capture-setting\style-components\SideModalSetting.vue
     * 5) appfrontend\src\views\capture-setting\style-components\SlideBarSetting.vue
     * 6) appfrontend\src\views\capture-setting\style-components\SliderSetting.vue
     * 7) appfrontend\src\views\capture-setting\style-components\SplitScreenSetting.vue
     */
    const barDisplayOptions = [
        { id: '1', text: 'Immediately' },
        { id: '2', text: '5 second delay' },
        { id: '3', text: '10 second delay' },
        { id: '4', text: '60 second delay' },
        { id: '5', text: 'After scrolling a little' },
        { id: '6', text: 'After scrolling to middle' },
        { id: '7', text: 'After scrolling to bottom' },
        { id: '8', text: 'User intends to leave' },
    ];

    /**
     * FOR USE OF CAPTURE STYLES IN MANAGE SITE MODULE
     * CALLING FROM: 
     * 1) appfrontend\src\views\capture-setting\style-components\BarSetting.vue
     * 2) appfrontend\src\views\capture-setting\style-components\ModalSetting.vue
     * 3) appfrontend\src\views\capture-setting\style-components\SideModalSetting.vue
     * 4) appfrontend\src\views\capture-setting\style-components\SlideBarSetting.vue
     * 5) appfrontend\src\views\capture-setting\style-components\SliderSetting.vue
     */
    const animationEffectOptions = [
        { value: null, text: 'Select Animated Effect' },
        {
            label: 'Attention Seekers',
            options: [
                { value: 'flash', text: 'flash' }
            ]
        },
        {
            label: 'Fading Entrances',
            options: [
                { value: 'fadeIn', text: 'fadeIn' }
            ]
        },
        {
            label: 'Fading Exits',
            options: [
                { value: 'fadeOut', text: 'fadeOut' },
                { value: 'fadeOutDown', text: 'fadeOutDown' },
                { value: 'fadeOutDownBig', text: 'fadeOutDownBig' },
                { value: 'fadeOutLeft', text: 'fadeOutLeft' },
                { value: 'fadeOutLeftBig', text: 'fadeOutLeftBig' },
                { value: 'fadeOutRightBig', text: 'fadeOutRightBig' },
                { value: 'fadeOutUp', text: 'fadeOutUp' },
                { value: 'fadeOutUp', text: 'fadeOutUp' },
                { value: 'fadeOutUpBig', text: 'fadeOutUpBig' },
            ],
        },
        {
            label: 'Rotating Exits',
            options: [
                { value: 'rotateOut', text: 'rotateOut' },
                { value: 'rotateOutDownLeft', text: 'rotateOutDownLeft' },
                { value: 'rotateOutDownRight', text: 'rotateOutDownRight' },
                { value: 'rotateOutUpLeft', text: 'rotateOutUpLeft' },
                { value: 'rotateOutUpRight', text: 'rotateOutUpRight' },
            ],
        },
        {
            label: 'Zoom Entrances',
            options: [
                { value: 'zoomIn', text: 'zoomIn' },
                { value: 'zoomInDown', text: 'zoomInDown' },
                { value: 'zoomInLeft', text: 'zoomInLeft' },
                { value: 'zoomInRight', text: 'zoomInRight' },
                { value: 'zoomInUp', text: 'zoomInUp' },
            ],
        },
        {
            label: 'Zoom Entrances',
            options: [
                { value: 'zoomOut', text: 'zoomOut' },
                { value: 'zoomOutDown', text: 'zoomOutDown' },
                { value: 'zoomOutLeft', text: 'zoomOutLeft' },
                { value: 'zoomOutRight', text: 'zoomOutRight' },
                { value: 'zoomOutUp', text: 'zoomOutUp' },
            ],
        },
        {
            label: 'Specials',
            options: [
                { value: 'hinge', text: 'hinge' },
                { value: 'rollOut', text: 'rollOut' }
            ]
        }
    ];

    /**
     * FOR USE OF CAPTURE STYLES IN MANAGE SITE MODULE
     * CALLING FROM: 
     * 1) appfrontend\src\views\capture-setting\style-components\ModalSetting.vue
     * 2) appfrontend\src\views\capture-setting\style-components\sideModalSetting.vue
     */
    const modalPlacementOptions = [
        { id: 'top', text: 'Top' },
        { id: 'middle', text: 'Middle' },
    ];

    /**
     * FOR USE OF CAPTURE STYLES IN MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\style-components\SliderSetting.vue
     */
    const sliderPlacementOptions = [
        { id: 'top-right', text: 'Top Right' },
        { id: 'bottom-right', text: 'Bottom Right' },
        { id: 'top-left', text: 'Top Left' },
        { id: 'bottom-left', text: 'Bottom Left' },
    ];

    /**
     * FOR USE OF CAPTURE STYLES IN MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\style-components\SideModalSetting.vue
     */
    const sideButtonPlacementOptions = [
        { id: 'top', text: 'Top' },
        { id: 'bottom', text: 'Bottom' },
        { id: 'left', text: 'Left' },
        { id: 'right', text: 'Right' },
    ];

    /**
     * FOR USE OF CAPTURE MODAL IMAGE IN MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\text-and-content-components\ImageSetting.vue
     */
    const modelImagePlacementOptions = [
        { id: 'top', text: 'Top' },
        { id: 'bottom', text: 'Bottom' },
        { id: 'left', text: 'Left' },
        { id: 'right', text: 'Right' },
        { id: 'above-caption', text: 'Above Caption' },
        { id: 'below-caption', text: 'Below Caption' },
    ];

    /**
     * FOR USE OF CAPTURE QUESTION IN TEXT AND CONTENT MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\text-and-content-components\QuestionSetting.vue
     */
    const questionFontFamily = [
        { id: 'Open Sans', text: 'Open Sans' },
        { id: 'Helvetica', text: 'Helvetica' },
        { id: 'Arial', text: 'Arial' },
        { id: 'Georgia', text: 'Georgia' },
        { id: 'Sans-Serif', text: 'Sans-Serif' },
    ];

    /**
     * FOR USE OF CAPTURE ANSWER 1 IN TEXT AND CONTENT MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\text-and-content-components\AnswerOneSetting.vue
     */
    const firstAnswerFontFamily = [
        { id: 'Open Sans', text: 'Open Sans' },
        { id: 'Helvetica', text: 'Helvetica' },
        { id: 'Arial', text: 'Arial' },
        { id: 'Georgia', text: 'Georgia' },
        { id: 'Sans-Serif', text: 'Sans-Serif' },
    ];

    /**
     * FOR USE OF CAPTURE ANSWER 2 IN TEXT AND CONTENT MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\text-and-content-components\AnswerTwoSetting.vue
     */
    const secondAnswerFontFamily = [
        { id: 'Open Sans', text: 'Open Sans' },
        { id: 'Helvetica', text: 'Helvetica' },
        { id: 'Arial', text: 'Arial' },
        { id: 'Georgia', text: 'Georgia' },
        { id: 'Sans-Serif', text: 'Sans-Serif' },
    ];

    /**
     * FOR USE OF CAPTURE TEXT IN AND CONTENT MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\text-and-content-components\TextSetting.vue
     */
    const textFontFamily = [
        { id: 'Open Sans', text: 'Open Sans' },
        { id: 'Helvetica', text: 'Helvetica' },
        { id: 'Arial', text: 'Arial' },
        { id: 'Georgia', text: 'Georgia' },
        { id: 'Sans-Serif', text: 'Sans-Serif' },
        { id: 'FuturaND-Bold', text: 'FuturaND-Bold' },
        { id: 'Futura Medium', text: 'Futura Medium' },
    ];

    /**
     * FOR USE OF CAPTURE FONT FAMILY FOR BUTTON IN AND CONTENT MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\text-and-content-components\TextSetting.vue
     */
    const textFontFamilyForButton = [
        { id: 'Open Sans', text: 'Open Sans' },
        { id: 'Helvetica', text: 'Helvetica' },
        { id: 'Arial', text: 'Arial' },
        { id: 'Georgia', text: 'Georgia' },
        { id: 'Sans-Serif', text: 'Sans-Serif' },
        { id: 'FuturaND-Bold', text: 'FuturaND-Bold' },
        { id: 'Futura Medium', text: 'Futura Medium' },
    ];

    /**
     * FOR USE OF CAPTURE FONT FAMILY FOR BUTTON IN AND CONTENT MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\text-and-content-components\TextSetting.vue
     */
    const textFontFamilyForInput = [
        { id: 'Open Sans', text: 'Open Sans' },
        { id: 'Helvetica', text: 'Helvetica' },
        { id: 'Arial', text: 'Arial' },
        { id: 'Georgia', text: 'Georgia' },
        { id: 'Sans-Serif', text: 'Sans-Serif' },
        { id: 'FuturaND-Bold', text: 'FuturaND-Bold' },
        { id: 'Futura Medium', text: 'Futura Medium' },
    ];

    /**
     * FOR USE OF CAPTURE FONT FAMILY FOR BUTTON IN AND CONTENT MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\text-and-content-components\TextSetting.vue
     */
    const textModelHtmlPlacement = [
        { id: 'top', text: 'Top' },
        { id: 'left', text: 'Left' },
        { id: 'right', text: 'Right' },
        { id: 'above-caption', text: 'Above Caption' },
        { id: 'below-caption', text: 'Below Caption' },
        { id: 'below-button', text: 'Below Button' },
    ];

    /**
     * FOR USE OF CAPTURE FONT FAMILY FOR BUTTON IN AND CONTENT MANAGE SITE MODULE
     * CALLING FROM: appfrontend\src\views\capture-setting\text-and-content-components\HtmlSetting.vue
     */
    const openURL = [
        { id: 0, text: 'Open URL in current tab' },
        { id: 1, text: 'Open URL in new tab' },
        { id: 2, text: 'Open URL in new window' },
    ];

    /**
     * USED IN RULES MODAL FOR ADD/EDIT RULES
     * CALLING FROM: appfrontend\src\views\capture-setting\tagret-components\AddEditRules.vue
     */
    const rulewhoShouldSee = [
        { id: 'all', text: 'All' },
        { id: 'any', text: 'Any' },
    ]

    /**
     * USED IN RULES MODAL FOR ADD/EDIT RULES
     * CALLING FROM: appfrontend\src\views\capture-setting\tagret-components\AddEditRules.vue
     */
    const ruleConditionsSegment = [
        { id: 'device', text: 'Device' },
        { id: 'referrer', text: 'Referrer' },
        { id: 'url', text: 'URL' },
        { id: 'urlQuery', text: 'URL Query' },
        { id: 'urlPath', text: 'URL Path' },
        { id: 'referrerDomain', text: 'Referrer Domain' },
        { id: 'interacted', text: 'Interacted With' },
        { id: 'closed', text: 'Closed' },
    ]

    /**
     * USED IN RULES MODAL FOR ADD/EDIT RULES
     * CALLING FROM: appfrontend\src\views\capture-setting\tagret-components\AddEditRules.vue
     */
    const deviceOprandOptions = [
        { id: 'is', text: 'Is' },
        { id: 'is_note', text: 'Is Not' },
    ]

    /**
     * USED IN RULES MODAL FOR ADD/EDIT RULES
     * CALLING FROM: appfrontend\src\views\capture-setting\tagret-components\AddEditRules.vue
     */
    const otherOprandOptions = [
        { id: 'is', text: 'Is' },
        { id: 'is_note', text: 'Is Not' },
        { id: 'includes', text: 'Includes' },
        { id: 'does_not_include', text: 'Does not include' },
    ]
    
    /**
     * USED IN RULES MODAL FOR ADD/EDIT RULES
     * CALLING FROM: appfrontend\src\views\capture-setting\tagret-components\AddEditRules.vue
     */
    const deviceValueOptions = [
        { id: 'mobile', text: 'Mobile' },
        { id: 'tablet', text: 'Tablet' },
        { id: 'computer', text: 'Computer' },
    ]

    /**
     * USED IN RULES MODAL FOR ADD/EDIT RULES
     * CALLING FROM: appfrontend\src\views\capture-setting\tagret-components\AddEditRules.vue
     */
    const ruleConditionOprand = [
        { id: 'days', text: 'Days' },
    ]

    return {
        barPlacementOptions,
        barSizeOptions,
        barDisplayOptions,
        animationEffectOptions,
        modalPlacementOptions,
        sliderPlacementOptions,
        sideButtonPlacementOptions,
        modelImagePlacementOptions,
        questionFontFamily,
        firstAnswerFontFamily,
        secondAnswerFontFamily,
        textFontFamily,
        textFontFamilyForButton,
        textFontFamilyForInput,
        textModelHtmlPlacement,
        openURL,
        rulewhoShouldSee,
        ruleConditionsSegment,
        deviceOprandOptions,
        otherOprandOptions,
        deviceValueOptions,
        ruleConditionOprand
    }
}